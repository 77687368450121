.btn-acoes {
  max-height: 28px;
}
.icon-acoes {
  margin-top: -5px;
  max-height: 24px !important;
}

.btn-ativa {
  background-color: #28a745;
  border-color: #28a745 !important;
  color: #fff;
  transition: 0.4s;
}
.btn-ativa:hover,
.btn-ativa:active,
.btn-ativa:focus {
  background-color: #fff !important;
  color: #28a745 !important;
  box-shadow: none;
}

.btn-desativa {
  height: 1.2rem;
  width: 2rem;
  background-color: #fff;
  border-color: #fff !important;
  border-radius: 6px;
  border-style: solid;
  color: #ffc107;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  transition: 0.4s;
}
.btn-desativa:hover,
.btn-desativa:active,
.btn-desativa:focus {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
  color: #fff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.btn-exclui {
  height: 1.2rem;
  width: 2rem;
  background-color: #fff;
  border-color: #fff !important;
  color: #cc5662;
  border-radius: 6px;
  border-style: solid;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  transition: 0.2s;
}
.btn-exclui:hover,
.btn-exclui:active,
.btn-exclui:focus {
  background-color: #cc5662 !important;
  border-color: #cc5662 !important;
  color: #fff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.botao-secundario {
  padding: 0.1rem 0.5rem;
  font-family: "Poppins";
  font-weight: 600;
  color: #4b4b4b;
  background-color: #fff !important;
  border-color: #fff !important;
  border-width: 1.95px;
  border-radius: 6px;
  border-style: solid;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  transition: 0.2s;
}
.botao-secundario:hover,
.botao-secundario:active,
.botao-secundario:focus {
  background-color: #1786c0 !important;
  border-color: #1786c0 !important;
  color: #fff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.botao-primario {
  padding: 0.1rem 0.5rem;
  font-family: "Poppins";
  font-weight: 600;
  color: #4b4b4b;
  background-color: #fff !important;
  border-color: #fff !important;
  border-width: 1.95px;
  border-radius: 6px;
  border-style: solid;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  transition: 0.2s;
}

.botao-primario:hover,
.botao-primario:active {
  background-color: #66cc66 !important;
  color: #fff !important;
  border-color: #66cc66 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.botao-primario:focus {
  box-shadow: none !important;
}

.botao-add {
  /* padding: 0.2px 4px; */
  font-weight: 500;
  color: #4b4b4b;
  background-color: #fff !important;
  border-color: #fff;
  border-width: 1.95px;
  border-radius: 6px;
  border-style: solid;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  transition: 0.2s;
}

.botao-add:hover,
.botao-add:active {
  background-color: #2cba8d !important;
  border-color: #2cba8d !important;
  color: #fff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.botao-add:focus {
  box-shadow: none !important;
}

.botao-primario-exclusao {
  background-color: #dc3545;
  color: #ffffff;
  border-color: #dc3545;
  border-width: 1.95px;
  border-radius: 6px;
}
.botao-primario-exclusao:hover,
.botao-primario-exclusao:active,
.botao-primario-exclusao:focus {
  background-color: #fff;
  border-color: #dc3545;
  color: #dc3545;
  box-shadow: none;
}
