.react-calendar {
  width: 400px;
  padding: 0.8rem;
  max-width: 100%;
  background: white;
  font-family: "Poppins";
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-family: "Poppins";
  transition-duration: 0.4s;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-weight: 500;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #e65050;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #a0a0a0;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  transition-duration: 0.4s;
}
.react-calendar__tile:disabled {
  color: #dadada;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #c5e7f1;
  /* color: white; */
  font-weight: 600;
  transition-duration: 0.4s;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: rgba(197, 231, 241, 0.6);
  transition-duration: 0.4s;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc !important;
  color: white;
  font-weight: 600;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff !important;
  transition-duration: 0.4s;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.marked {
  background: #2cba8d !important;
  color: white !important;
  font-weight: 600;
  transition-duration: 0.4s;
}
.marked:enabled:hover,
.marked:enabled:focus,
.marked:enabled:active,
.marked:enabled:target {
  background: rgba(44, 186, 141, 0.6) !important;
  transition-duration: 0.4s;
}

@media (max-width: 765px) {
  .react-calendar {
    width: 300px;
    padding: 0.8rem;
  }
}
