.css-10hburv-MuiTypography-root {
  margin: 0;
  /* font-family: "Poppins" !important; */
  font-weight: 400;
  font-size: 12px !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
  color: #4b4b4b;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 20px !important;
}

.css-yb0lig {
  margin: 0;
  /* font-family: "Poppins" !important; */
  font-weight: 400;
  font-size: 12px !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
  color: #4b4b4b;
}
