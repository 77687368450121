.fc .fc-toolbar-title {
  font-size: 1em;
  font-family: "Poppins";
  margin: 0;
}

.fc .fc-button {
  border-radius: 0;
  overflow: visible;
  text-transform: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: 1rem;
}

.fc .fc-button {
  display: inline-block;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 0.8em;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.4em 0.6em;
  line-height: 1.5;
  border-radius: 0.15em;
  margin-right: 0.35em;
  margin-top: 0.3rem;
}
.fc .fc-button:hover {
  text-decoration: none;
}
.fc .fc-button:focus {
  outline: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}
.fc .fc-button:disabled {
  opacity: 0.65;
}

.fc .fc-button-primary {
  color: "#4b4b4b";
  color: var(--fc-button-text-color, "#4b4b4b");
  background-color: #fff;
  padding: 0.2em 0.8em;
  background-color: var(--fc-button-bg-color, #fff);
  border-color: #fff;
  border-color: var(--fc-button-border-color, #fff);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  transition-duration: 0.4s;
}
.fc .fc-button-primary:hover {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #2cba8d;
  background-color: var(--fc-button-hover-bg-color, #2cba8d);
  border-color: #2cba8d;
  border-color: var(--fc-button-hover-border-color, #2cba8d);
}
.fc .fc-button-primary:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}
.fc .fc-button-primary:disabled {
  /* not DRY */
  color: #4b4b4b;
  color: var(--fc-button-text-color, #4b4b4b);
  background-color: #b4b4b4;
  background-color: var(--fc-button-bg-color, #b4b4b4);
  border-color: #b4b4b4;
  border-color: var(--fc-button-border-color, #b4b4b4); /* overrides :hover */
}
.fc .fc-button-primary:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #2cba8d;
  background-color: var(--fc-button-hover-bg-color, #2cba8d);
  border-color: #2cba8d;
  border-color: var(--fc-button-hover-border-color, #2cba8d);
}
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.fc-direction-ltr .fc-list-day-text,
.fc-direction-rtl .fc-list-day-side-text {
  float: left;
  font-family: "Poppins";
}
.fc-direction-ltr .fc-list-day-side-text,
.fc-direction-rtl .fc-list-day-text {
  float: right;
  font-family: "Poppins";
}

.fc .fc-list-empty {
  background-color: rgba(208, 208, 208, 0.3);
  background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; /* vertically aligns fc-list-empty-inner */
  text-align: center;
  padding: 12px;
}
.fc .fc-list-empty-cushion {
  margin: 5em 0;
}

.fc .fc-Todos-button:focus,
.fc .fc-Agendados-button:focus,
.fc .fc-Disponiveis-button:focus {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #2cba8d;
  background-color: var(--fc-button-hover-bg-color, #2cba8d);
  border-color: #2cba8d;
  border-color: var(--fc-button-hover-border-color, #2cba8d);
}

.fc-event {
  cursor: pointer;
}

@media (max-width: 765px) {
  .fc .fc-toolbar-title {
    font-size: 0.8em;
    font-family: "Poppins";
    margin: 0;
  }
  .fc .fc-button {
    display: inline-block;
    font-family: "Poppins";
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.4em 0.65em;
    font-size: 0.6em;
    line-height: 1.5;
    border-radius: 0.15em;
    margin-right: 0.3em;
    margin-top: 0.5rem;
  }
}
